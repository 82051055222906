import { type FunctionComponent, useEffect, useState } from 'react';
import { type VideoFieldsFragment } from '../../../__generated__/graphql-client-types';
import { FEATURE_FLAGS } from '../../../constants/general';
import { PLAY_BUTTON } from '../../../constants/videos';
import { buildGTMVideoModalOpened } from '../../../helpers/analytics/gtm/event-builders';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { useVideoThumbnail } from '../../../hooks/videos/videos.hooks';
import { ClickableElement } from '../../buttons/clickable-element/clickable-element.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { EmbeddedVideo } from '../embedded-video/embedded-video.component';
import { Modal } from '../modal/modal.component';
import { VideoModal } from '../video-modal/video-modal.component';

export type VideoCardProps = {
	video: VideoFieldsFragment;
	previewWidthPx?: number;
	previewHeightPx?: number;
	shouldPause?: boolean;
	autoPlay?: boolean;
	shouldLoadEagerly?: boolean;
	isSimpleVideoModal?: boolean;
	triggerElement?: JSX.Element;
};

/**
 * TODO: SODEV-35405
 * This component should use VideoModal component internally and use the CloudinaryImage as the children with the useVideoThumbnail() hook.
 */
export const VideoCard: FunctionComponent<VideoCardProps> = ({
	video,
	previewWidthPx,
	previewHeightPx,
	shouldPause,
	autoPlay,
	shouldLoadEagerly,
	isSimpleVideoModal,
	triggerElement
}) => {
	const trackEvent = useTrackEvent();
	const isPdpMediaGalleryReorderActive = useFeature(FEATURE_FLAGS.PDP_MEDIA_GALLERY_REORDER);

	const [showModal, setShowModal] = useState(false);

	function open() {
		setShowModal(true);
	}

	function close() {
		setShowModal(false);
	}

	const { imagePreview, type, isWistiaFallback } = useVideoThumbnail(video);

	useEffect(() => {
		if (isPdpMediaGalleryReorderActive && showModal) {
			trackEvent(buildGTMVideoModalOpened());
		}
	}, [showModal]);
	return (
		<>
			<ClickableElement ariaLabel="Open video modal" onClick={open} onKeyDown={handleKeys(['Enter', ' '], open)}>
				{triggerElement ? (
					triggerElement
				) : (
					<CloudinaryImage
						publicID={imagePreview}
						options={{
							type,
							width: previewWidthPx || 456,
							height: previewHeightPx || 274,
							crop: isWistiaFallback ? 'lpad' : 'pad',
							overlay: { publicId: PLAY_BUTTON }
						}}
						useDimensions={false}
						description={video.description}
					/>
				)}
			</ClickableElement>
			{showModal &&
				(isSimpleVideoModal ? (
					<Modal title={<></>} ariaLabel="simple video modal" size="large" closeAction={close}>
						<div className="center">
							<EmbeddedVideo
								video={video}
								shouldPause={shouldPause}
								autoPlay={autoPlay}
								shouldLoadEagerly={shouldLoadEagerly}
							/>
						</div>
					</Modal>
				) : (
					<VideoModal video={video} onClose={close} />
				))}
		</>
	);
};
