import { type FunctionComponent, useEffect, useId, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import useScript from 'react-script-hook';
import { type VideoFieldsFragment } from '../../../__generated__/graphql-client-types';

const YOUTUBE_SCRIPT = { type: 'text/javascript', src: 'https://www.youtube.com/iframe_api', defer: true };
const WISTIA_SCRIPT = { type: 'text/javascript', src: `//fast.wistia.com/assets/external/E-v1.js`, defer: true };

export type EmbedProps = {
	hash: string;
	autoPlay: boolean; // support for `node-store` parity (play in modal functionality)
	shouldPause: boolean;
	// NOTE: supported aspect ratios need to be added to postcss.config.js
	aspectRatio?: '16x9' | '1x1';
};

const WistiaEmbed: FunctionComponent<EmbedProps> = ({ hash, autoPlay, shouldPause }) => {
	// This is to help reduce id collisions on the page by making each instance of a video on the page unique
	const uid = useId();
	const videoPlayer = useRef<any>(null);
	const JSONP_SCRIPT = { type: 'text/javascript', src: `//fast.wistia.com/embed/medias/${hash}.jsonp`, defer: true };
	const videoTargetClasses = `wistia_embed wistia_async_${hash}`;

	// Load the Wistia API and video script
	useScript({
		src: WISTIA_SCRIPT.src,
		defer: WISTIA_SCRIPT.defer
	});
	useScript({
		src: JSONP_SCRIPT.src,
		defer: JSONP_SCRIPT.defer
	});

	useEffect(() => {
		function playerReady(player) {
			videoPlayer.current = player;
			if (autoPlay) {
				player.play();
			}
		}

		window._wq = window._wq || [];
		window._wq.push({ id: `${uid}-${hash}`, onReady: playerReady });
	}, [hash, uid, autoPlay]);

	useEffect(() => {
		if (shouldPause && videoPlayer.current) {
			videoPlayer.current.pause();
		}
	}, [shouldPause]);

	return (
		<div className="wistia_responsive_padding">
			<div className="wistia_responsive_wrapper">
				<div
					id={`${uid}-${hash}`}
					className={`${videoTargetClasses} seo=false videoFoam=true`}
					style={{ height: '100%', width: '100%' }}>
					&nbsp;
				</div>
			</div>
		</div>
	);
};

export const YouTubeEmbed: FunctionComponent<EmbedProps> = ({ hash, shouldPause, autoPlay, aspectRatio = '16x9' }) => {
	// This is to help reduce id collisions on the page by making each instance of a video on the page unique
	const uid = useId();
	const videoPlayer = useRef<any>(null);

	// Load the YouTube API
	useScript({
		src: YOUTUBE_SCRIPT.src,
		defer: YOUTUBE_SCRIPT.defer
	});

	// initialize the player
	useEffect(() => {
		const initPlayer = () => {
			const ytplayer = new window.YT.Player(`ytplayer-${uid}-${hash}`, {
				videoId: hash,
				playerVars: {
					autoplay: autoPlay ? 1 : 0,
					rel: 0
				}
			});
			videoPlayer.current = ytplayer;
		};

		if (window.YT?.Player) {
			initPlayer();
		} else {
			/* onYouTubePlayerAPIReady is called one time after the YouTube player API JavaScript
			 * has been downloaded. Cache existing function in case there are multiple videos
			 * waiting to be initialized.
			 *
			 * @see https://developers.google.com/youtube/iframe_api_reference
			 *
			 * If this component is used inside a carousel with infinite scrolling, the
			 * component will be generated 2 to 3 times. (Two times by default and three times if
			 * the video component is the last item in the array.)
			 */
			const existingOnYouTubePlayerAPIReady = window.onYouTubePlayerAPIReady;
			window.onYouTubePlayerAPIReady = () => {
				initPlayer();
				if (existingOnYouTubePlayerAPIReady) {
					existingOnYouTubePlayerAPIReady();
				}
			};
		}
		return () => {
			if (videoPlayer.current?.destroy) {
				videoPlayer.current.destroy();
			}
		};
	}, [autoPlay, hash, uid]);

	// pause if needed
	useEffect(() => {
		if (shouldPause && videoPlayer.current?.pauseVideo) {
			videoPlayer.current.pauseVideo();
		}
	}, [shouldPause]);

	// dangerouslySetInnerHTML is used here so that when youTube replaces the div react won't care
	return (
		<div
			className={`aspect-ratio aspect-ratio--${aspectRatio}`}
			dangerouslySetInnerHTML={{ __html: `<div id="ytplayer-${uid}-${hash}" class="aspect-ratio--object"></div>` }}></div>
	);
};

export type EmbeddedVideoProps = {
	shouldPause?: boolean;
	video: VideoFieldsFragment;
	autoPlay?: boolean; // support for `node-store` parity (play in modal functionality)
	shouldLoadEagerly?: boolean;
};

type VideoEmbedProps = Omit<EmbeddedVideoProps, 'shouldLoadEagerly'>;

const WistiaOrYoutube: FunctionComponent<VideoEmbedProps> = ({ video, shouldPause = false, autoPlay = false }) => {
	return video.streamProviderCode === 1 ? (
		<WistiaEmbed hash={video.hashKey} autoPlay={autoPlay} shouldPause={shouldPause} />
	) : (
		<YouTubeEmbed hash={video.hashKey} autoPlay={autoPlay} shouldPause={shouldPause} />
	);
};

export const EmbeddedVideo: FunctionComponent<EmbeddedVideoProps> = ({ shouldLoadEagerly = false, ...props }) => {
	const { ref, inView } = useInView({ triggerOnce: true });

	return <div ref={ref}>{shouldLoadEagerly ? <WistiaOrYoutube {...props} /> : inView && <WistiaOrYoutube {...props} />}</div>;
};
