import type { RouteProps } from 'react-router';

export interface RouteConfig extends Pick<RouteProps, 'component' | 'exact' | 'path' | 'sensitive' | 'strict'> {
	DANGEROUSLY_IS_LIVE?: boolean; // make route live in SPA routing
	backgroundStyle?: 'default' | 'grey';
	ddRum?: {
		// sets a custom DD RUM view name using useSetRumViewName in the layout component
		rumViewName?: string;
		// setting this to true requires calling useSetRumViewName in a child component
		rumViewNameSetByChild?: boolean;
	};
	disableAppUpdate?: boolean; // disable version changed app updating for this route.
	// Specify a loading component to show when loading this route.
	// TODO: we can support this using fallback in loadable-components, but would require a jsx transform to test our route transformer
	// loading?: JSX.Element;
	layout?: 'simple' | 'minimized';
	requiresAccount?: boolean;
	requiresAuthentication?: boolean;
	requiresEmployeeAuthentication?: boolean;
}
/**
 * A HOC that configures the react router with route configuration. This must be used with the typescript configure-route-transformer
 * which will rip the HOC out and only use it for configuration.
 */
/**
 * @param config The configuration data. It mostly mirrors RouteProps, but also supports VersionedRouter
 * config and specifying a custom loading component.
 * @param component The component being wrapped.
 */
export function configureRoute<P extends object>(config: RouteConfig, Component: React.ComponentType<P>): React.ComponentType<P> {
	return Component;
}
